export enum QuestionType {
  STRING,
  BOOLEAN,
  DATE,
  CHECKBOX,
}

export const QuestionTypeValues = {
  [QuestionType.STRING]: "Text",
  [QuestionType.BOOLEAN]: "Boolean",
  [QuestionType.DATE]: "Date",
  [QuestionType.CHECKBOX]: "Checkbox",
};
