import Card from "antd/lib/card";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CrudTable } from "../components/crud/crudTable.component";
import { Role } from "../enums/role.enum";
import { IPermissions } from "../interfaces/permissions.interface";
import { vacancySchema } from "../schemas/vacancy.schema";
import { vacancyService } from "../services/vacancy.service";
import { appUtils } from "../utils/app.util";
import { authenticationUtils } from "../utils/authentication.util";
import { RouteView } from "./routeView.abstract";

interface IState {
  permissions: IPermissions;
}

export class VacancyView extends RouteView<RouteComponentProps<any>, IState> {
  public state = {
    permissions: {
      create: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
      update: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
      delete: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
    },
  };

  componentDidMount() {
    appUtils.setTitle("Vacancies");
  }

  render() {
    return (
      <Card title={"Vacancies"}>
        <CrudTable
          permissions={this.state.permissions}
          service={vacancyService}
          schema={vacancySchema}
        />
      </Card>
    );
  }
}
