import Card from "antd/lib/card";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CrudTable } from "../components/crud/crudTable.component";
import { CommitteeForm } from "../components/forms/committeeForm.component";
import { Role } from "../enums/role.enum";
import { IPermissions } from "../interfaces/permissions.interface";
import { committeeSchema } from "../schemas/committee.schema";
import { committeeService } from "../services/committee.service";
import { appUtils } from "../utils/app.util";
import { authenticationUtils } from "../utils/authentication.util";
import { RouteView } from "./routeView.abstract";

interface IState {
  permissions: IPermissions;
}

export class CommitteeView extends RouteView<RouteComponentProps<any>, IState> {
  public state = {
    permissions: {
      create: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
      update: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
      delete: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
    },
  };

  componentDidMount() {
    appUtils.setTitle("Committees");
  }

  render() {
    return (
      <Card title={"Committees"}>
        <CrudTable
          permissions={this.state.permissions}
          service={committeeService}
          schema={committeeSchema}
          customCreateForm={CommitteeForm}
          customUpdateForm={CommitteeForm}
        />
      </Card>
    );
  }
}
