import Form from "antd/lib/form";
import Input from "antd/lib/input";
import React, { Component } from "react";
import { CrudForm } from "../../components/crud/crudForm.component";
import { ICompany } from "../../interfaces/company.interface";
import { ICompanyFileRequest } from "../../interfaces/companyFileRequest.interface";
import { IFormProps } from "../../interfaces/formProps.interface";
import { IPermissions } from "../../interfaces/permissions.interface";
import { companySchema } from "../../schemas/company.schema";
import { companyService } from "../../services/company.service";
import { fileService } from "../../services/file.service";

interface IState {
  company: ICompanyFileRequest;
}

interface IProps extends IFormProps {
  model: ICompany;
  permissions?: IPermissions;
}

export class CompanyForm extends Component<IProps, IState> {
  private readonly = false;
  public state: IState = {
    company: {} as ICompanyFileRequest,
  };

  componentDidMount() {
    this.setState({
      company: this.props.model || this.state.company,
    });

    this.readonly =
      (this.props.model && (this.props.permissions || {}).update === false) ||
      (!this.props.model && (this.props.permissions || {}).create === false);
  }

  render() {
    return (
      <div>
        <Form.Item label={"Logo"}>
          <img src={this.state.company.imageData || this.state.company.image} />
          <Input
            hidden={this.readonly}
            type="file"
            onChange={async (e) => {
              this.state.company.imageData = await fileService.toBase64(
                e.target.files[0]
              );
              this.forceUpdate();
            }}
          />
        </Form.Item>
        <CrudForm
          permissions={this.props.permissions}
          action={this.props.model ? "update" : "create"}
          service={companyService}
          schema={companySchema}
          onSubmit={async (model: ICompany) => this.onSubmit(model)}
          model={this.props.model}
        />
      </div>
    );
  }

  private onSubmit(model: ICompanyFileRequest) {
    if (this.state.company.imageData) {
      model.imageData = this.state.company.imageData.split("base64,")[1];
    }
    delete model.image;

    this.props.onSubmit(model);
  }
}
