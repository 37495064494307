import Button from "antd/lib/button/button";
import DatePicker from "antd/lib/date-picker";
import locale from "antd/lib/date-picker/locale/nl_NL";
import Form from "antd/lib/form";
import Input from "antd/lib/input/Input";
import Select from "antd/lib/select";
import Switch from "antd/lib/switch";
import _get from "lodash/get";
import moment from "moment";
import "moment/locale/nl";
import React, { Component } from "react";
import { Sheet } from "strcss";
import { RichText } from "../../components/richText.component";
import { IModel } from "../../interfaces/model.interface";
import { IPermissions } from "../../interfaces/permissions.interface";
import { ISchema } from "../../interfaces/schema.interface";
import { ISchemaProperties } from "../../interfaces/schemaProperties.interface";
import { CrudService } from "../../services/crud.abstract.service";
import { stateUtils } from "../../utils/state.util";

interface IProps {
  service: CrudService<IModel>;
  schema: ISchema;
  action: "create" | "update";
  model?: IModel;
  permissions?: IPermissions;
  onSubmit: (model: IModel) => Promise<void>;

  submitOnChange?: boolean;
}

interface IState {
  formItems: React.ReactElement<any>[];
  model: IModel;
  loading: boolean;
  readonly: boolean;
}

export class CrudForm extends Component<IProps, IState> {
  public state = {
    formItems: [],
    model: {} as IModel,
    loading: false,
    readonly: false,
  };

  async componentDidMount() {
    this.state.readonly =
      this.props.permissions &&
      ((this.props.action === "create" &&
        this.props.permissions.create === false) ||
        (this.props.action === "update" &&
          this.props.permissions.update === false));

    await this.resetForm(Date.now().toString());
  }

  private async resetForm(key: string) {
    this.state.model = { ...this.props.model };
    await this.buildFormItems(key);
  }

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.submit(e.target as HTMLFormElement);
        }}
      >
        {this.state.formItems}
        {!this.props.submitOnChange && (
          <Form.Item className={sheet.map.submit}>
            <Button
              hidden={this.state.readonly}
              loading={this.state.loading}
              type="primary"
              htmlType="submit"
              className="width-full"
            >
              Save
            </Button>
          </Form.Item>
        )}
      </Form>
    );
  }

  /**
   * Builds a list of form items
   */
  private async buildFormItems(key: string): Promise<void> {
    const formItems = [] as React.ReactElement<any>[];
    for (const item of Object.keys(this.props.schema)) {
      const properties = this.props.schema[item];

      if (properties.showForm && properties.showForm() === false) {
        continue;
      }

      formItems.push(
        <Form.Item
          key={item + key}
          label={properties.label || item}
          required={properties.required}
        >
          {await this.getInputElement(item, properties)}
        </Form.Item>
      );
    }

    this.setState({ formItems });
  }

  /**
   * Returns the right input element for the property
   * @param key
   * @param properties
   */
  private async getInputElement(
    key: string,
    properties: ISchemaProperties
  ): Promise<React.ReactElement<any>> {
    const path = `model.${properties.path || key}`;
    let refModels = [] as IModel[];

    // define the default value
    let defaultValue = _get(this.state, path);
    if (properties.isArray) {
      if (!Array.isArray(defaultValue)) {
        defaultValue = [];
      }
      defaultValue = defaultValue.map((v) => v + "");
    } else if (defaultValue !== false) {
      defaultValue = [null, undefined].includes(defaultValue)
        ? undefined
        : defaultValue + "";
    }

    // fetch refs if applicable
    if (properties.ref) {
      refModels = await properties.ref.getAll();
      properties.type = {} as { [key: string]: string };
      for (const model of refModels) {
        properties.type[model._id] = properties.render
          ? properties.render(model)
          : model._id;
      }
    }

    // create a select box if the type is a key value pair
    if (typeof properties.type === "object") {
      const options = [] as React.ReactElement<any>[];
      for (const value of Object.keys(properties.type)) {
        options.push(
          <Select.Option key={key + value} value={value}>
            {properties.type[value]}
          </Select.Option>
        );
      }

      return (
        <Select
          disabled={this.state.readonly}
          showSearch
          className={this.state.readonly ? "display-as-text" : ""}
          mode={!!properties.isArray ? "multiple" : ""}
          optionFilterProp="children"
          defaultValue={defaultValue || undefined}
          placeholder={properties.placeholder}
          onChange={(value) => {
            stateUtils.writeChange(
              this,
              path,
              isNaN(Number(value)) ? value : +value
            );

            if (this.props.submitOnChange) {
              this.submit();
            }
          }}
        >
          {options}
        </Select>
      );
    }
    if (properties.type === "boolean") {
      return (
        <Switch
          defaultChecked={!!defaultValue}
          disabled={this.state.readonly}
          onChange={(checked) => {
            stateUtils.writeChange(this, path, checked);

            if (this.props.submitOnChange) {
              this.submit();
            }
          }}
        />
      );
    }

    // create a datepicker when the type is a date
    const showTime = properties.type === "datetime";
    if (properties.type === "date" || showTime) {
      return (
        <DatePicker
          showTime={showTime}
          disabled={this.state.readonly}
          format={showTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
          locale={locale}
          className={this.state.readonly ? "display-as-text" : ""}
          defaultValue={defaultValue ? moment(defaultValue) : null}
          placeholder={properties.placeholder}
          onChange={(value) => {
            stateUtils.writeChange(this, path, value);

            if (this.props.submitOnChange) {
              this.submit();
            }
          }}
        />
      );
    }

    if (properties.type === "text") {
      return (
        <RichText
          readonly={this.state.readonly}
          value={defaultValue}
          onChange={(value) => {
            this.state.model[key] = value;

            if (this.props.submitOnChange) {
              this.submit();
            }
          }}
        />
      );
    }

    // default to the defined type
    return (
      <Input
        disabled={this.state.readonly}
        className={this.state.readonly ? "display-as-text" : ""}
        defaultValue={defaultValue}
        onChange={(event) => {
          stateUtils.writeChange(this, path, event.target.value);

          if (this.props.submitOnChange) {
            this.submit();
          }
        }}
        type={properties.type}
        name={path}
        required={properties.required}
        placeholder={properties.placeholder || ""}
      />
    );
  }

  /**
   * Submit the form
   * @param form
   */
  private async submit(form?: HTMLFormElement) {
    if (this.props.submitOnChange) {
      return this.props.onSubmit(this.state.model);
    }

    this.setState({ loading: true });
    try {
      await this.props.onSubmit(this.state.model);
      if (form) {
        form.reset();
      }
      await this.resetForm(Date.now().toString());
    } catch (e) {}
    this.setState({ loading: false });
  }
}

const sheet = new Sheet(`
  map submit
    marginTop 35px
`);
