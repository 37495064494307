import countryList from "country-list";
import { studyPhase } from "../constants/studyPhase.constant";
import { studyProgram } from "../constants/studyProgram.constant";
import { university } from "../constants/university.constant";
import { GenderValues } from "../enums/gender.enum";
import { Role, RoleValues } from "../enums/role.enum";
import { ISchema } from "../interfaces/schema.interface";
import { authenticationUtils } from "../utils/authentication.util";
import { baseSchema } from "./base.schema";

export const userSchema: ISchema = {
  ...baseSchema,

  email: {
    type: "email",
    required: true,
    showTable: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
    label: "email",
    placeholder: "kees@gmail.com",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  password: {
    type: "string",
    showTable: () => false,
    label: "password",
    placeholder: "Secret",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  role: {
    type: RoleValues,
    required: true,
    showTable: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
    label: "role",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },

  firstName: {
    type: "string",
    required: true,
    label: "first name",
    placeholder: "Kees",
  },
  lastName: {
    type: "string",
    required: true,
    label: "Last name",
    placeholder: "de Vries",
  },
  dateOfBirth: {
    type: "date",
    required: true,
    showTable: () => false,
    label: "Date of birth",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  gender: {
    type: GenderValues,
    required: true,
    showTable: () => false,
    label: "gender",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  nationality: {
    type: countryList.getNameList(),
    showTable: () => false,
    label: "Nationality",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  address: {
    type: "string",
    showTable: () => false,
    label: "Address",
    placeholder: "221B Baker Street",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  postalCode: {
    type: "string",
    showTable: () => false,
    label: "Postal code",
    placeholder: "1234AB",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  city: {
    type: "string",
    showTable: () => false,
    label: "City",
    placeholder: "London",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  phoneNumber: {
    type: "string",
    showTable: () => false,
    label: "phone_number",
    placeholder: "(+31) 612345678",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  university: {
    type: university,
    showTable: () => false,
    label: "University",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  shopKey: {
    type: "string",
    showTable: () => false,
    label: "Shop key",
    placeholder: "0123456",
  },
  studentNumber: {
    type: university,
    showTable: () => false,
    label: "Student number",
    placeholder: "0123456",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  studyProgram: {
    type: studyProgram,
    showTable: () => false,
    label: "Study program",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  studyPhase: {
    type: studyPhase,
    showTable: () => false,
    label: "Study phase",
    placeholder: "Select",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  bankAccount: {
    type: "string",
    showTable: () => false,
    label: "Bank account",
    placeholder: "IBAN number",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
  interestedInCommittee: {
    type: "boolean",
    showTable: () => false,
    label: "Interested in committee",
    placeholder: "",
    showForm: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
  },
};
