import Icon from "antd/lib/icon";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Sheet } from "strcss";

interface IProps {
  route: string;
  text: string;
  icon?: string;
  hide?: boolean;
}

export class NavigationItem extends Component<IProps> {
  render() {
    if (this.props.hide) {
      return <></>;
    }

    return (
      <NavLink
        to={this.props.route}
        isActive={(match, location) => location.pathname === this.props.route}
        activeClassName={sheet.map.active}
        className={sheet.map.a}
      >
        {this.props.icon && (
          <Icon type={this.props.icon} className={sheet.map.icon} />
        )}
        {this.props.text}
      </NavLink>
    );
  }
}

const sheet = new Sheet(`
    
  map a
    color rgba(0,0,0,.54)
    padding 0 20px
    whiteSpace nowrap
  on focus
    textDecoration none
  on hover
    color rgba(0,0,0,.87)

  map icon
    marginRight 6px

  map active
    color #1890ff
    fontWeight bold
    transition none
  on hover
    color #1890ff
`);
