import React, { Component, Props } from "react";
import { Sheet } from "strcss";

import { Header } from "./header.component";

export class Layout extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="container">{this.props.children}</div>
        <footer className={sheet.map.footer}>
          D.S.A. Pattern - Copyright &copy; {new Date().getFullYear()}
        </footer>
      </>
    );
  }
}

const sheet = new Sheet(`
  map footer
    textAlign center
    margin 20px 0 60px 0
`);
