import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Switch from "antd/lib/switch";
import React, { Component } from "react";
import { CrudForm } from "../../components/crud/crudForm.component";
import { IFormProps } from "../../interfaces/formProps.interface";
import { IPermissions } from "../../interfaces/permissions.interface";
import { IUser } from "../../interfaces/user.interface";
import { IUserFileRequest } from "../../interfaces/userFileRequest.interface";
import { userSchema } from "../../schemas/user.schema";
import { fileService } from "../../services/file.service";
import { userService } from "../../services/user.service";

interface IState {
  model: IUserFileRequest;
}

interface IProps extends IFormProps {
  model: IUser;
  permissions?: IPermissions;
}

export class UserForm extends Component<IProps, IState> {
  private readonly = false;
  public state: IState = {
    model: {} as IUserFileRequest,
  };

  componentDidMount() {
    this.setState({
      model: this.props.model || this.state.model,
    });

    this.readonly =
      (this.props.model && (this.props.permissions || {}).update === false) ||
      (!this.props.model && (this.props.permissions || {}).create === false);
  }

  render() {
    return (
      <div>
        <Form.Item label={"Photo"}>
          <img src={this.state.model.imageData || this.state.model.image} />
          <Input
            hidden={this.readonly}
            type="file"
            onChange={async (e) => {
              this.state.model.imageData = await fileService.toBase64(
                e.target.files[0]
              );
              this.forceUpdate();
            }}
          />
        </Form.Item>
        <CrudForm
          permissions={this.props.permissions}
          action={this.props.model ? "update" : "create"}
          service={userService}
          schema={userSchema}
          onSubmit={async (model: IUserFileRequest) => this.mergeModels(model)}
          model={this.props.model}
          submitOnChange
        />

        {this.props.model && (
          <Form.Item label="Account activated">
            <Switch
              disabled={this.readonly}
              checked={
                this.state.model.status && this.state.model.status.isActivated
              }
              onChange={() => {
                this.state.model.status.isActivated = !this.state.model.status
                  .isActivated;
                this.forceUpdate();
              }}
            />
          </Form.Item>
        )}
        <Form.Item style={{ marginTop: "35px" }}>
          <Button
            hidden={this.readonly}
            type="primary"
            onClick={() => this.onSubmit(this.state.model)}
            className="width-full"
          >
            Save
          </Button>
        </Form.Item>
      </div>
    );
  }

  private mergeModels(model: IUserFileRequest) {
    this.state.model = {
      ...this.state.model,
      ...model,
    };
  }

  private onSubmit(model: IUserFileRequest) {
    if (this.state.model.imageData) {
      model.imageData = this.state.model.imageData.split("base64,")[1];
    }
    delete model.image;

    this.props.onSubmit(model);
  }
}
