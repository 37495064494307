import { INavigation } from "../interfaces/navigation.interface";
import { CrudService } from "./crud.abstract.service";

export const navigationService = new (class NavigationService extends CrudService<
  INavigation
> {
  constructor() {
    super("navigation");
  }
})();
