export enum Package {
  BRONZE,
  SILVER,
  GOLD,
}

export const PackageValues = {
  [Package.BRONZE]: "Bronze",
  [Package.SILVER]: "Silver",
  [Package.GOLD]: "Gold",
};
