import React from "react";
import { Router } from "./components/router";
import { authenticationService } from "./services/authentication.service";

export default class App extends React.PureComponent {
  constructor(props: any) {
    super(props);

    // validate the users' session
    if (authenticationService.getSessionToken()) {
      authenticationService.validate().catch();
    }
  }

  public render(): React.ReactNode {
    return <Router />;
  }
}
