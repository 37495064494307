import React, { Component } from "react";
import RichTextEditor, { EditorValue } from "react-rte";

interface IProps {
  value?: string;
  onChange: (value: string) => void;
  readonly?: boolean;
}
interface IState {
  state: EditorValue;
  editSource: boolean;
}

export class RichText extends Component<IProps, IState> {
  public state: IState = {
    state: RichTextEditor.createEmptyValue(),
    editSource: false,
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        state: RichTextEditor.createValueFromString(this.props.value, "html"),
      });
    }
  }

  render() {
    if (this.props.readonly) {
      return <div dangerouslySetInnerHTML={{ __html: this.props.value }} />;
    }

    if (this.state.editSource) {
      return (
        <>
          <textarea
            className="ant-input"
            defaultValue={this.state.state.toString("html")}
            onChange={event => this.onChangeTextArea(event.target.value)}
            style={{ minHeight: "200px" }}
          />
          <a
            onClick={() => {
              this.setState({ editSource: false });
            }}
          >
            back to editor
          </a>
        </>
      );
    }

    return (
      <>
        <RichTextEditor
          value={this.state.state}
          onChange={state => this.onChange(state)}
        />
        <a
          onClick={() => {
            this.setState({ editSource: true });
          }}
        >
          edit source
        </a>
      </>
    );
  }

  private onChange(state) {
    this.setState({ state });
    this.props.onChange(state.toString("html"));
  }

  private onChangeTextArea(value) {
    this.state.state = RichTextEditor.createValueFromString(value, "html");
    this.props.onChange(value);
  }
}
