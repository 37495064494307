import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import React, { Component } from "react";
import { CrudForm } from "../../components/crud/crudForm.component";
import { IEvent } from "../../interfaces/event.interface";
import { IEventFileRequest } from "../../interfaces/eventFileRequest.interface";
import { IFormProps } from "../../interfaces/formProps.interface";
import { eventSchema } from "../../schemas/event.schema";
import { eventService } from "../../services/event.service";
import { fileService } from "../../services/file.service";
import { participationService } from "../../services/participation.service";
import { QuestionsForm } from "./questionsForm.component";

interface IState {
  model: IEventFileRequest;
  didMount: boolean;
}

interface IProps extends IFormProps {
  model: IEvent;
}

export class EventForm extends Component<IProps, IState> {
  private readonly = false;
  public state: IState = {
    model: {} as IEventFileRequest,
    didMount: false,
  };

  componentDidMount() {
    this.setState({
      model: this.props.model || this.state.model,
      didMount: true,
    });

    this.readonly =
      (this.props.model && (this.props.permissions || {}).update === false) ||
      (!this.props.model && (this.props.permissions || {}).create === false);
  }

  render() {
    if (this.state.didMount === false) {
      return <></>;
    }
    return (
      <div>
        {this.props.model && this.state.model.allowParticipations && (
          <Button
            size="small"
            onClick={() =>
              participationService.downloadCsv(
                ["event", this.props.model._id, "csv"].join("/")
              )
            }
          >
            Download participants
          </Button>
        )}
        <Form.Item label={"Photo"}>
          <img src={this.state.model.imageData || this.state.model.image} />
          <Input
            hidden={this.readonly}
            type="file"
            onChange={async (e) => {
              this.state.model.imageData = await fileService.toBase64(
                e.target.files[0]
              );
              this.forceUpdate();
            }}
          />
        </Form.Item>
        <CrudForm
          action={this.props.model ? "update" : "create"}
          service={eventService}
          schema={eventSchema}
          onSubmit={async (model: IEventFileRequest) => this.mergeModels(model)}
          model={this.props.model}
          permissions={this.props.permissions}
          submitOnChange
        />
        {this.state.model.allowParticipations && (
          <>
            <Form.Item label={"Maximum participants"}>
              <Input
                readOnly={this.readonly}
                className={this.readonly ? "display-as-text" : ""}
                type="number"
                placeholder="No limit"
                defaultValue={
                  this.props.model &&
                  this.props.model.maximumParticipants &&
                  this.props.model.maximumParticipants.toString()
                }
                onChange={(e) => {
                  this.state.model.maximumParticipants = +e.target.value;
                }}
              />
            </Form.Item>

            <QuestionsForm
              key={(this.state.model.questions || []).length}
              questions={this.state.model.questions}
              readonly={this.readonly}
              onSubmit={(questions) => {
                this.state.model.questions = questions;
              }}
            />
          </>
        )}

        <div className="flex">
          <Form.Item label={"Action label"} className="flex1">
            <Input
              readOnly={this.readonly}
              className={this.readonly ? "display-as-text" : ""}
              type="text"
              placeholder="Buy tickets here!"
              defaultValue={
                this.props.model &&
                this.props.model.action &&
                this.props.model.action.label
              }
              onChange={(e) => {
                if (!this.state.model.action) {
                  this.state.model.action = {};
                }
                this.state.model.action.label = e.target.value;
              }}
            />
          </Form.Item>

          <Form.Item label={"Action url"} className="flex1">
            <Input
              readOnly={this.readonly}
              className={this.readonly ? "display-as-text" : ""}
              type="text"
              placeholder="https://shop.dsapattern.nl/nl/"
              defaultValue={
                this.props.model &&
                this.props.model.action &&
                this.props.model.action.url
              }
              onChange={(e) => {
                if (!this.state.model.action) {
                  this.state.model.action = {};
                }
                this.state.model.action.url = e.target.value;
              }}
            />
          </Form.Item>
        </div>

        <Form.Item style={{ marginTop: "35px" }}>
          <Button
            hidden={this.readonly}
            type="primary"
            onClick={() => this.onSubmit(this.state.model)}
            className="width-full"
          >
            Save
          </Button>
        </Form.Item>
      </div>
    );
  }

  private mergeModels(model: IEventFileRequest) {
    this.setState({
      model: {
        ...this.state.model,
        ...model,
      },
    });
  }

  private onSubmit(model: IEventFileRequest) {
    if (this.state.model.imageData) {
      model.imageData = this.state.model.imageData.split("base64,")[1];
    }
    delete model.image;

    this.props.onSubmit(model);
  }
}
