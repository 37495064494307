import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import React, { Component } from "react";
import { QuestionTypeValues } from "../../../enums/questionType.enum";
import { IParticipationQuestion } from "../../../interfaces/participationQuestion.interface";

interface IState {
  question: IParticipationQuestion;
}

interface IProps {
  onSubmit: (question: IParticipationQuestion) => any;
  question: IParticipationQuestion;
  readonly?: boolean;
}

export class QuestionRow extends Component<IProps, IState> {
  private userFields = [
    "",
    "email",
    "image",
    "firstName",
    "lastName",
    "dateOfBirth",
    "nationality",
    "address",
    "postalCode",
    "city",
    "phoneNumber",
    "university",
    "studyProgram",
    "studyPhase",
    "bankAccount",
  ];

  public state: IState = {
    question: {} as IParticipationQuestion,
  };

  componentDidMount() {
    this.setState({
      question: this.props.question || this.state.question,
    });
  }

  render() {
    if (!this.state.question) {
      return <></>;
    }
    return (
      <>
        <Form.Item style={{ margin: "15px 0" }}>
          <Input.Group compact>
            <Select
              disabled={this.props.readonly}
              showSearch
              className={[this.props.readonly ? "display-as-text" : ""].join(
                " "
              )}
              style={{ width: "20%" }}
              optionFilterProp="children"
              defaultValue={(this.props.question.type || 0) + ""}
              placeholder={"Select"}
              onChange={(value: string) => {
                this.state.question.type = +value;
                this.submit();
              }}
            >
              {Object.keys(QuestionTypeValues).map((type) => (
                <Select.Option key={type} value={type}>
                  {QuestionTypeValues[type]}
                </Select.Option>
              ))}
            </Select>
            <Input
              disabled={this.props.readonly}
              className={this.props.readonly ? "display-as-text" : ""}
              style={{ width: "23%" }}
              placeholder="What is your name?"
              defaultValue={this.state.question.question}
              onChange={(e) => {
                this.state.question.question = e.target.value;
                this.submit();
              }}
            />
            <Input
              disabled={this.props.readonly}
              className={this.props.readonly ? "display-as-text" : ""}
              style={{ width: "23%" }}
              placeholder="John"
              defaultValue={this.state.question.placeholder}
              onChange={(e) => {
                this.state.question.placeholder = e.target.value;
                this.submit();
              }}
            />
            <Select
              disabled={this.props.readonly}
              showSearch
              className={[this.props.readonly ? "display-as-text" : ""].join(
                " "
              )}
              style={{ width: "23%" }}
              optionFilterProp="children"
              defaultValue={this.props.question.default || ""}
              placeholder={"Select"}
              onChange={(value: string) => {
                this.state.question.default = value;
                this.submit();
              }}
            >
              {this.userFields.map((field) => (
                <Select.Option key={field} value={field}>
                  {field}
                </Select.Option>
              ))}
            </Select>
            <Button
              type="danger"
              style={{ width: "11%" }}
              disabled={this.props.readonly}
              onClick={() => {
                this.state.question = null;
                this.submit();
              }}
            >
              <Icon type="delete" />
            </Button>
          </Input.Group>
        </Form.Item>
      </>
    );
  }

  private submit() {
    this.props.onSubmit(this.state.question);
  }
}
