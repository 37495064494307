import { IParticipation } from "../interfaces/participation.interface";
import { CrudService } from "./crud.abstract.service";

export const participationService = new (class ParticipationService extends CrudService<
  IParticipation
> {
  constructor() {
    super("participation");
  }
})();
