import { Role } from "../enums/role.enum";
import { authenticationService } from "../services/authentication.service";

export const authenticationUtils = new (class AuthenticationUtils {
  /**
   * Returns true if the users role satisfies the given roles.
   * @param roles
   */
  public satisfiesRoles(roles: Role[]): boolean {
    const user = (authenticationService.getSessionToken() || { user: null })
      .user;

    return user !== null && roles.includes(user.role);
  }

  /**
   * Returns the users full name
   */
  public getUserFullName(): string[] {
    const session = authenticationService.getSessionToken();
    if (session) {
      return [session.user.firstName, session.user.lastName];
    }
    return [];
  }

  /**
   * Returns the users first name
   */
  public getUserFirstName(): string {
    return this.getUserFullName()[0];
  }
})();
