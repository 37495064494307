import Button from "antd/lib/button";
import Form from "antd/lib/form";
import React, { Component } from "react";
import { QuestionType } from "../../enums/questionType.enum";
import { IParticipationQuestion } from "../../interfaces/participationQuestion.interface";
import { QuestionRow } from "./components/questionRow.component";

interface IState {
  questions: IParticipationQuestion[];
}

interface IProps {
  onSubmit: (questions: IParticipationQuestion[]) => any;
  questions: IParticipationQuestion[];
  readonly?: boolean;
}

export class QuestionsForm extends Component<IProps, IState> {
  public state: IState = {
    questions: [],
  };

  componentDidMount() {
    let questions = this.props.questions || this.state.questions;
    if (!questions.some((q) => q.question === "Email")) {
      questions.unshift(emailQuestion);
    }

    this.setState({ questions });
  }

  render() {
    return (
      <>
        <Form.Item>
          <h3>Form questions</h3>
        </Form.Item>
        <Form.Item>
          <strong
            style={{
              width: "20%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Type
          </strong>
          <strong
            style={{
              width: "23%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Question
          </strong>
          <strong
            style={{
              width: "23%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Placeholder
          </strong>
          <strong
            style={{
              width: "23%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Default field
          </strong>
        </Form.Item>

        {this.state.questions.map((q, i) => (
          <QuestionRow
            key={q._id || i}
            question={q}
            onSubmit={(question) => {
              if (question) {
                this.changeRow(question, i);
              } else {
                this.removeRow(i);
              }
              this.submit();
            }}
            readonly={q.question === "Email" || this.props.readonly}
          />
        ))}

        <Form.Item style={{ marginTop: "0" }}>
          <Button
            hidden={this.props.readonly}
            type="default"
            onClick={() => this.addQuestion()}
            className="width-full"
          >
            Add new question
          </Button>
        </Form.Item>
      </>
    );
  }

  private addQuestion() {
    this.state.questions.push({
      type: QuestionType.STRING,
    } as IParticipationQuestion);
    this.forceUpdate();
  }

  private changeRow(changedQuestion: IParticipationQuestion, index: number) {
    const questions = this.state.questions;
    questions[index] = {
      ...questions[index],
      ...changedQuestion,
    };

    this.setState({ questions });
  }

  private removeRow(index: number) {
    const questions = this.state.questions;
    questions.splice(index, 1);

    this.setState({ questions });
  }

  private submit() {
    this.props.onSubmit(this.state.questions);
  }
}

const emailQuestion: IParticipationQuestion = {
  question: "Email",
  type: QuestionType.STRING,
  default: "email",
  placeholder: "member@dsapattern.nl",
} as IParticipationQuestion;
