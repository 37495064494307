import { ICommittee } from "../interfaces/committee.interface";
import { CrudService } from "./crud.abstract.service";

export const committeeService = new (class CommitteeService extends CrudService<
  ICommittee
> {
  constructor() {
    super("committee");
  }
})();
