import { IVacancy } from "../interfaces/vacancy.interface";
import { CrudService } from "./crud.abstract.service";

export const vacancyService = new (class VacancyService extends CrudService<
  IVacancy
> {
  constructor() {
    super("vacancy");
  }
})();
