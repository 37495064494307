import moment from "moment";
import { ISchema } from "../interfaces/schema.interface";
import { baseSchema } from "./base.schema";

export const timelineEventSchema: ISchema = {
  ...baseSchema,

  title: {
    type: "string",
    required: true,
    label: "Title",
    placeholder: "Historic event",
  },
  description: {
    type: "text",
    required: true,
    label: "Description",
    placeholder: "",
    showTable: () => false,
  },
  timestamp: {
    type: "date",
    required: true,
    label: "Timestamp",
    placeholder: "Select",
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
};
