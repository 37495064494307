import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import React, { Component } from "react";
import { ICredentials } from "../interfaces/credentials.interface";
import { stateUtils } from "../utils/state.util";

interface IProps {
  submit: (credentials: ICredentials) => void;
}
interface IState {
  credentials: ICredentials;
  isLoading: boolean;
}

export class LoginForm extends Component<IProps, IState> {
  public state: IState = {
    credentials: {
      email: "",
      password: "",
    },
    isLoading: false,
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Form onSubmit={this.submit.bind(this)} layout="vertical">
        <Form.Item label={"Email"} required>
          <Input
            disabled={isLoading}
            onChange={(event) => {
              stateUtils.writeChange(
                this,
                "credentials.email",
                event.target.value
              );
            }}
            prefix={<Icon type="user" />}
            type="text"
            placeholder={"johndoe@dsapattern.com"}
            required
          />
        </Form.Item>
        <Form.Item label={"Password"} required>
          <Input
            disabled={isLoading}
            onChange={(event) => {
              stateUtils.writeChange(
                this,
                "credentials.password",
                event.target.value
              );
            }}
            prefix={<Icon type="lock" />}
            type="password"
            placeholder={"Password"}
            required
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={isLoading}
            type="primary"
            htmlType="submit"
            className="width-full"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    );
  }

  /**
   * Call the submit method received through props
   * @param e
   */
  private async submit(e) {
    e.preventDefault();

    try {
      this.setState({ isLoading: true });
      await this.props.submit(this.state.credentials);

      message.success("You have successfully logged in");
    } catch (error) {
      message.error("You have provided invalid credentials");
    }
    this.setState({ isLoading: false });
  }
}
