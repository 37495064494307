import Card from "antd/lib/card";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CrudTable } from "../components/crud/crudTable.component";
import { CompanyForm } from "../components/forms/companyForm.component";
import { Role } from "../enums/role.enum";
import { IPermissions } from "../interfaces/permissions.interface";
import { companySchema } from "../schemas/company.schema";
import { companyService } from "../services/company.service";
import { appUtils } from "../utils/app.util";
import { authenticationUtils } from "../utils/authentication.util";
import { RouteView } from "./routeView.abstract";

interface IState {
  permissions: IPermissions;
}

export class CompanyView extends RouteView<RouteComponentProps<any>, IState> {
  public state = {
    permissions: {
      create: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
      update: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
      delete: authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
    },
  };

  componentDidMount() {
    appUtils.setTitle("Partners");
  }

  render() {
    return (
      <Card title={"Partners"}>
        <CrudTable
          permissions={this.state.permissions}
          service={companyService}
          schema={companySchema}
          customCreateForm={CompanyForm}
          customUpdateForm={CompanyForm}
        />
      </Card>
    );
  }
}
