import Button from "antd/lib/button";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import React, { Component } from "react";
import { Sheet } from "strcss";
import { IFormProps } from "../../interfaces/formProps.interface";
import { IModel } from "../../interfaces/model.interface";
import { IPermissions } from "../../interfaces/permissions.interface";
import { ISchema } from "../../interfaces/schema.interface";
import { CrudService } from "../../services/crud.abstract.service";
import { appUtils } from "../../utils/app.util";
import { CrudForm } from "./crudForm.component";

interface IProps {
  onClose: (model: IModel) => void;
  service: CrudService<IModel>;
  schema: ISchema;
  action: "create" | "update";
  model?: IModel;
  visible: boolean;
  title: string;
  permissions?: IPermissions;

  customForm?: React.ComponentClass<IFormProps>;
  onCreate?: (model: IModel) => Promise<IModel>;
  onUpdate?: (model: IModel) => Promise<IModel>;
}

export class CrudModal extends Component<IProps> {
  componentWillReceiveProps() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <Modal
        footer={false}
        title={this.props.title}
        visible={this.props.visible}
        maskClosable={false}
        onCancel={() => {
          this.props.onClose(null);
        }}
      >
        {this.props.action === "update" && (
          <div className="align-right">
            <Button
              hidden={!(this.props.permissions || { delete: true }).delete}
              type="danger"
              size="small"
              onClick={this.confirmDelete.bind(this)}
            >
              Remove
            </Button>
          </div>
        )}
        {this.props.customForm ? (
          <this.props.customForm
            key={appUtils.getUID()}
            model={this.props.model}
            onSubmit={this.handleSubmit.bind(this)}
            permissions={this.props.permissions}
          />
        ) : (
          <CrudForm
            service={this.props.service}
            schema={this.props.schema}
            model={this.props.model}
            action={this.props.action}
            onSubmit={this.handleSubmit.bind(this)}
            permissions={this.props.permissions}
          />
        )}
      </Modal>
    );
  }

  /**
   * Handles the submit
   * @param model
   */
  private async handleSubmit(model: IModel): Promise<IModel> {
    if (model === null) {
      this.props.onClose(model);
      return model;
    }

    let response = null;
    try {
      switch (this.props.action) {
        case "create":
          if (this.props.onCreate) {
            response = await this.props.onCreate(model);
          } else {
            response = await this.props.service.create(model);
          }
          break;
        case "update":
          if (this.props.onUpdate) {
            response = await this.props.onUpdate(model);
          } else {
            response = await this.props.service.update(model);
          }
          break;
      }

      message.success("The model has successfully been saved");
      this.props.onClose(response);
    } catch (error) {
      message.error(error.message);
      throw new Error(error.message);
    }

    return response;
  }

  private confirmDelete(): void {
    Modal.confirm({
      title: "Remove",
      content: "Are you sure you want to remove this model?",
      okText: "Remove",
      cancelText: "Cancel",
      onOk: this.deleteModel.bind(this),
      type: "warning",
    });
  }

  private async deleteModel() {
    await this.props.service.delete(this.props.model._id);
    this.props.onClose(null);
  }
}

const sheet = new Sheet(`
`);
