export const appUtils = new (class AppUtils {
  private idCount = 0;
  private idPrefix = "";

  /**
   * Set the app title
   * @param title
   */
  public setTitle(title: string): void {
    const titleParts = ["D.S.A. Pattern"];
    if (title) {
      titleParts.push("-");
      titleParts.push(title);
    }

    document.title = titleParts.join(" ");
  }

  /**
   * Returns a unique id
   */
  public getUID(): string {
    const id = this.idCount++;
    if (id >= Number.MAX_SAFE_INTEGER) {
      this.idPrefix += id;
      this.idCount = 0;
    }

    return this.idPrefix + id;
  }
})();
