/**
 * This file is used to import styles of used Ant components.
 * Only import styles of used components to keep the bundle size minimal.
 */
import "antd/lib/auto-complete/style/css";
import "antd/lib/button/style/css";
import "antd/lib/card/style/css";
import "antd/lib/checkbox/style/css";
import "antd/lib/collapse/style/css";
import "antd/lib/date-picker/style/css";
import "antd/lib/form/style/css";
import "antd/lib/icon/style/css";
import "antd/lib/input/style/css";
import "antd/lib/message/style/css";
import "antd/lib/modal/style/css";
import "antd/lib/switch/style/css";
import "antd/lib/table/style/css";

require("./ant.overrides.css");
