import { Role } from "./enums/role.enum";
import { authenticationService } from "./services/authentication.service";
import { authenticationUtils } from "./utils/authentication.util";
import { BoardView } from "./views/board.view";
import { CommitteeView } from "./views/committee.view";
import { CompanyView } from "./views/company.view";
import { EventView } from "./views/event.view";
import { LoginView } from "./views/login.view";
import { LogoutView } from "./views/logout.view";
import { NavigationView } from "./views/navigation.view";
import { PageView } from "./views/page.view";
import { TimelineView } from "./views/timeline.view";
import { UsersView } from "./views/users.view";
import { VacancyView } from "./views/vacancy.view";

export const Routes: {
  [name: string]: {
    path: string;
    access: () => boolean;
    exact: boolean;
    component: React.ComponentClass;
  };
} = {
  home: {
    path: "/",
    access: () => !!authenticationService.getSessionToken(),
    component: EventView,
    exact: true,
  },
  boards: {
    path: "/boards",
    access: () => !!authenticationService.getSessionToken(),
    component: BoardView,
    exact: true,
  },
  companies: {
    path: "/partners",
    access: () =>
      authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
    component: CompanyView,
    exact: true,
  },
  committees: {
    path: "/committees",
    access: () => !!authenticationService.getSessionToken(),
    component: CommitteeView,
    exact: true,
  },
  events: {
    path: "/events",
    access: () => !!authenticationService.getSessionToken(),
    component: EventView,
    exact: true,
  },
  logout: {
    path: "/logout",
    access: () => !!authenticationService.getSessionToken(),
    component: LogoutView,
    exact: true,
  },
  login: {
    path: "/login",
    access: () => !authenticationService.getSessionToken(),
    component: LoginView,
    exact: true,
  },
  timeline: {
    path: "/timeline",
    access: () => !!authenticationService.getSessionToken(),
    component: TimelineView,
    exact: true,
  },
  users: {
    path: "/users",
    access: () =>
      authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
    component: UsersView,
    exact: true,
  },
  vacancies: {
    path: "/vacancies",
    access: () =>
      authenticationUtils.satisfiesRoles([Role.MODERATOR, Role.ADMIN]),
    component: VacancyView,
    exact: true,
  },
  pages: {
    path: "/pages",
    access: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
    component: PageView,
    exact: true,
  },
  navigation: {
    path: "/navigation",
    access: () => authenticationUtils.satisfiesRoles([Role.ADMIN]),
    component: NavigationView,
    exact: true,
  },

  notFound: {
    path: null,
    access: () => true,
    component: LoginView,
    exact: false,
  },
};
