import moment from "moment";
import { ICompany } from "../interfaces/company.interface";
import { ISchema } from "../interfaces/schema.interface";
import { companyService } from "../services/company.service";
import { baseSchema } from "./base.schema";

export const vacancySchema: ISchema = {
  ...baseSchema,

  name: {
    type: "string",
    required: true,
    label: "Name",
    placeholder: "Portal specialist",
  },
  description: {
    type: "text",
    required: true,
    label: "Description",
    placeholder: "",
    showTable: () => false,
  },
  companyId: {
    type: "string",
    ref: companyService,
    required: true,
    label: "Company",
    placeholder: "Select",
    showTable: () => false,
    render: (model: ICompany) => model.name,
  },
  type: {
    type: "string",
    required: true,
    label: "Type",
    placeholder: "Full-time, Parttime, Internship etc.",
  },
  company: {
    type: "string",
    ref: companyService,
    required: true,
    label: "Company",
    placeholder: "Select",
    showForm: () => false,
    render: (model: ICompany) => model.name,
  },
  website: {
    type: "string",
    required: true,
    label: "Website url",
    placeholder: "http://www.aperturescience.com/tester",
    showTable: () => false,
  },
  published: {
    type: "date",
    required: true,
    label: "Published",
    placeholder: "Select",
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
  expired: {
    type: "date",
    required: true,
    label: "Expired",
    placeholder: "Select",
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
};
