import { PageType, PageTypeValues } from "../enums/pageType.enum";
import { ISchema } from "../interfaces/schema.interface";
import { baseSchema } from "./base.schema";

export const pageSchema: ISchema = {
  ...baseSchema,
  type: {
    type: PageTypeValues,
    required: true,
    label: "Type",
    placeholder: "Select",
    default: () => PageType.CONTENT as any,
  },
  path: {
    type: "string",
    required: true,
    label: "URL path",
    placeholder: "fancy-page",
  },
  title: {
    type: "string",
    required: true,
    label: "Title",
    placeholder: "Fancy page title",
  },
  description: {
    type: "text",
    label: "Page description",
    placeholder: "We stand for...",
    showTable: () => false,
  },
  content: {
    type: "text",
    label: "Page content",
    placeholder: "Aperture Science Inc.",
    showTable: () => false,
  },
  destination: {
    type: "string",
    label: "Redirect destination",
    placeholder: "http://facebook.com/",
    showTable: () => false,
  },
  isMemberFeature: {
    type: "boolean",
    required: true,
    label: "Is member feature",
    render: (bool) => (bool ? "Yes" : "No"),
  },
};
