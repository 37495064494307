import { INavigation } from "../interfaces/navigation.interface";
import { ISchema } from "../interfaces/schema.interface";
import { navigationService } from "../services/navigation.service";
import { baseSchema } from "./base.schema";

export const navigationSchema: ISchema = {
  ...baseSchema,

  label: {
    type: "string",
    required: true,
    label: "Label",
    placeholder: "Link label",
  },
  url: {
    type: "string",
    required: true,
    label: "Url",
    placeholder: "http://... for external, /commit... for internal",
  },
  isMemberFeature: {
    type: "boolean",
    required: true,
    label: "Is member feature",
    render: (bool) => (bool ? "Yes" : "No"),
  },
  position: {
    type: "number",
    required: true,
    label: "Position on the navigation bar",
    placeholder: "Will be sorted ascending",
  },
  navigationId: {
    type: "string",
    ref: navigationService,
    label: "Parent navigation item",
    placeholder: "Select",
    showTable: () => false,
    render: (model: INavigation) => model.label,
  },
};
