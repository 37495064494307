import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import React, { Component } from "react";
import { CrudForm } from "../../components/crud/crudForm.component";
import { ICommittee } from "../../interfaces/committee.interface";
import { ICommitteeFileRequest } from "../../interfaces/committeeFileRequest.interface";
import { IFormProps } from "../../interfaces/formProps.interface";
import { IPermissions } from "../../interfaces/permissions.interface";
import { committeeSchema } from "../../schemas/committee.schema";
import { committeeService } from "../../services/committee.service";
import { fileService } from "../../services/file.service";
import { MembersForm } from "./membersForm.component";

interface IState {
  model: ICommitteeFileRequest;
  didMount: boolean;
}

interface IProps extends IFormProps {
  model: ICommittee;
  permissions?: IPermissions;
}

export class CommitteeForm extends Component<IProps, IState> {
  private readonly = false;
  public state: IState = {
    model: {} as ICommitteeFileRequest,
    didMount: false,
  };

  componentDidMount() {
    this.setState({
      model: this.props.model || this.state.model,
      didMount: true,
    });

    this.readonly =
      (this.props.model && (this.props.permissions || {}).update === false) ||
      (!this.props.model && (this.props.permissions || {}).create === false);
  }

  render() {
    if (this.state.didMount === false) {
      return <></>;
    }
    return (
      <div>
        <Form.Item label={"Photo"}>
          <img src={this.state.model.imageData || this.state.model.image} />
          <Input
            hidden={this.readonly}
            type="file"
            onChange={async (e) => {
              this.state.model.imageData = await fileService.toBase64(
                e.target.files[0]
              );
              this.forceUpdate();
            }}
          />
        </Form.Item>
        <CrudForm
          action={this.props.model ? "update" : "create"}
          service={committeeService}
          schema={committeeSchema}
          onSubmit={async (model: ICommitteeFileRequest) =>
            this.mergeModels(model)
          }
          model={this.props.model}
          permissions={this.props.permissions}
          submitOnChange
        />

        {/* Select members */}
        <MembersForm
          key={(this.state.model.members || []).length}
          members={this.state.model.members}
          readonly={this.readonly}
          onSubmit={(members) => {
            this.state.model.members = members;
          }}
        />

        <Form.Item style={{ marginTop: "35px" }}>
          <Button
            hidden={this.readonly}
            type="primary"
            onClick={() => this.onSubmit(this.state.model)}
            className="width-full"
          >
            Save
          </Button>
        </Form.Item>
      </div>
    );
  }

  private mergeModels(model: ICommitteeFileRequest) {
    this.state.model = {
      ...this.state.model,
      ...model,
    };
  }

  private onSubmit(model: ICommitteeFileRequest) {
    if (this.state.model.imageData) {
      model.imageData = this.state.model.imageData.split("base64,")[1];
    }
    delete model.image;

    this.props.onSubmit(model);
  }
}
