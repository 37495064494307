import React, { Component } from "react";
import { Route, RouteProps } from "react-router-dom";
import { LoginView } from "../views/login.view";

interface IProps extends RouteProps {
  access: () => boolean;
}

export class SecureRoute extends Component<IProps> {
  render() {
    if (!this.props.access()) {
      return <Route {...this.props} component={LoginView} />;
    }

    return <Route {...this.props} />;
  }
}
