import moment from "moment";
import { ISchema } from "../interfaces/schema.interface";
import { baseSchema } from "./base.schema";

export const boardSchema: ISchema = {
  ...baseSchema,

  name: {
    type: "string",
    required: true,
    label: "Board name",
    placeholder: "Board x year n",
  },
  startDate: {
    type: "date",
    required: true,
    label: "Start date",
    placeholder: "Select",
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
  endDate: {
    type: "date",
    required: true,
    label: "End date",
    placeholder: "Select",
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
};
