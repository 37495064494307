import { ITimelineEvent } from "../interfaces/timelineEvent.interface";
import { CrudService } from "./crud.abstract.service";

export const timelineEventService = new (class TimelineEventService extends CrudService<
  ITimelineEvent
> {
  constructor() {
    super("timeline");
  }
})();
