import { ISchema } from "../interfaces/schema.interface";
import { baseSchema } from "./base.schema";

export const committeeSchema: ISchema = {
  ...baseSchema,

  name: {
    type: "string",
    required: true,
    label: "Committee name",
    placeholder: "Creative naming committee",
  },
  slogan: {
    type: "string",
    required: true,
    label: "Slogan",
    placeholder: "Always at the top of the page",
  },
  intro: {
    type: "text",
    required: true,
    label: "Introduction",
    placeholder: "Short introduction",
    showTable: () => false,
  },
  description: {
    type: "text",
    required: true,
    label: "Description",
    placeholder: "Who are they and what do they stand for?",
    showTable: () => false,
  },
};
