import Card from "antd/lib/card";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CrudTable } from "../components/crud/crudTable.component";
import { UserForm } from "../components/forms/userForm.component";
import { Role } from "../enums/role.enum";
import { IPermissions } from "../interfaces/permissions.interface";
import { userSchema } from "../schemas/user.schema";
import { userService } from "../services/user.service";
import { appUtils } from "../utils/app.util";
import { authenticationUtils } from "../utils/authentication.util";
import { RouteView } from "./routeView.abstract";

interface IState {
  permissions: IPermissions;
}

export class UsersView extends RouteView<RouteComponentProps<any>, IState> {
  public state = {
    permissions: {
      create: authenticationUtils.satisfiesRoles([Role.ADMIN]),
      update: authenticationUtils.satisfiesRoles([Role.ADMIN]),
      delete: authenticationUtils.satisfiesRoles([Role.ADMIN]),
    },
  };

  componentDidMount() {
    appUtils.setTitle("Users");
  }

  render() {
    return (
      <Card title={"Users"}>
        <CrudTable
          permissions={this.state.permissions}
          service={userService}
          schema={userSchema}
          customCreateForm={UserForm}
          customUpdateForm={UserForm}
        />
      </Card>
    );
  }
}
