import Card from "antd/lib/card";
import React from "react";
import { Sheet } from "strcss";
import { LoginForm } from "../components/loginForm.component";
import { ICredentials } from "../interfaces/credentials.interface";
import { Routes } from "../routes";
import { authenticationService } from "../services/authentication.service";
import { appUtils } from "../utils/app.util";
import { RouteView } from "./routeView.abstract";

export class LoginView extends RouteView {
  componentDidMount() {
    appUtils.setTitle("Login");

    // navigate to home if the user has a session
    if (!!authenticationService.getSessionToken()) {
      this.push(Routes.home.path);
    }
  }

  public render() {
    return (
      <Card title={"Login"} className={sheet.map.login}>
        <LoginForm submit={this.login.bind(this)} />
      </Card>
    );
  }

  private async login(credentials: ICredentials): Promise<void> {
    await authenticationService.authenticate(credentials);
    this.push(Routes.home.path);
  }
}

const sheet = new Sheet(`
  map login
    maxWidth 400px
    position relative
    margin 0 auto

  map forgotPassword
    textAlign center
    width 100%
    display block
`);
