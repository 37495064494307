import React, { Component } from "react";
import { Sheet } from "strcss";
import { Routes } from "../routes";
import { NavigationItem } from "./navigationItem.component";

export class Navigation extends Component {
  render() {
    return (
      <nav className={sheet.map.list}>
        {/* <NavigationItem
          hide={!Routes.home.access()}
          route={Routes.home.path}
          text={"Home"}
          icon="home"
        /> */}
        <NavigationItem
          hide={!Routes.login.access()}
          route={Routes.login.path}
          text={"Home"}
          icon="home"
        />

        <NavigationItem
          hide={!Routes.boards.access()}
          route={Routes.boards.path}
          text={"Boards"}
          icon="star"
        />
        <NavigationItem
          hide={!Routes.committees.access()}
          route={Routes.committees.path}
          text={"Committees"}
          icon="bulb"
        />
        <NavigationItem
          hide={!Routes.events.access()}
          route={Routes.events.path}
          text={"Events"}
          icon="calendar"
        />
        <NavigationItem
          hide={!Routes.companies.access()}
          route={Routes.companies.path}
          text={"Partners"}
          icon="bank"
        />
        <NavigationItem
          hide={!Routes.timeline.access()}
          route={Routes.timeline.path}
          text={"Timeline"}
          icon="line-chart"
        />
        <NavigationItem
          hide={!Routes.users.access()}
          route={Routes.users.path}
          text={"Users"}
          icon="team"
        />
        <NavigationItem
          hide={!Routes.vacancies.access()}
          route={Routes.vacancies.path}
          text={"Vacancies"}
          icon="solution"
        />
        <NavigationItem
          hide={!Routes.pages.access()}
          route={Routes.pages.path}
          text={"Pages"}
          icon="file"
        />
        <NavigationItem
          hide={!Routes.navigation.access()}
          route={Routes.navigation.path}
          text={"Navigation"}
          icon="rocket"
        />
      </nav>
    );
  }
}

const sheet = new Sheet(`
  map list
    display flex
`);
