import Card from "antd/lib/card";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CrudTable } from "../components/crud/crudTable.component";
import { EventForm } from "../components/forms/eventForm.component";
import { Role } from "../enums/role.enum";
import { IPermissions } from "../interfaces/permissions.interface";
import { eventSchema } from "../schemas/event.schema";
import { eventService } from "../services/event.service";
import { appUtils } from "../utils/app.util";
import { authenticationUtils } from "../utils/authentication.util";
import { RouteView } from "./routeView.abstract";

interface IState {
  permissions: IPermissions;
}

export class EventView extends RouteView<RouteComponentProps<any>, IState> {
  public state = {
    permissions: {
      create: authenticationUtils.satisfiesRoles([
        Role.MODERATOR,
        Role.ADMIN,
        Role.PROMOTER,
      ]),
      update: authenticationUtils.satisfiesRoles([
        Role.MODERATOR,
        Role.ADMIN,
        Role.PROMOTER,
      ]),
      delete: authenticationUtils.satisfiesRoles([
        Role.MODERATOR,
        Role.ADMIN,
        Role.PROMOTER,
      ]),
    },
  };

  componentDidMount() {
    appUtils.setTitle("Events");
  }

  render() {
    return (
      <Card title={"Events"}>
        <CrudTable
          permissions={this.state.permissions}
          service={eventService}
          schema={eventSchema}
          customCreateForm={EventForm}
          customUpdateForm={EventForm}
        />
      </Card>
    );
  }
}
