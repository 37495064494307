import moment from "moment";
import { PackageValues } from "../enums/package.enum";
import { ISchema } from "../interfaces/schema.interface";
import { baseSchema } from "./base.schema";

export const companySchema: ISchema = {
  ...baseSchema,

  name: {
    type: "string",
    required: true,
    label: "Name",
    placeholder: "Aperture Science Inc.",
  },
  description: {
    type: "text",
    required: true,
    label: "Description",
    placeholder: "Who are they and what do they stand for?",
    showTable: () => false,
  },
  package: {
    type: PackageValues,
    required: true,
    label: "Package",
    placeholder: "Select",
  },
  website: {
    type: "string",
    label: "Website",
    placeholder: "http://www.aperturescience.com/",
    showTable: () => false,
  },
  phoneNumber: {
    type: "string",
    label: "Phone number",
    placeholder: "(+31) 612345678",
    showTable: () => false,
  },
  address: {
    type: "string",
    label: "Address",
    placeholder: "31 Spooner Street",
    showTable: () => false,
  },
  postalCode: {
    type: "string",
    label: "Postal code",
    placeholder: "1213AB",
    showTable: () => false,
  },
  city: {
    type: "string",
    label: "City",
    placeholder: "Quahog",
    showTable: () => false,
  },
  priority: {
    type: "number",
    label: "Priority",
    placeholder: "A higher number gets the company up front",
  },
  published: {
    type: "date",
    required: true,
    label: "Published",
    placeholder: "Select",
    showTable: () => true,
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
  expired: {
    type: "date",
    required: true,
    label: "Expired",
    placeholder: "Select",
    showTable: () => true,
    render: (date: Date) => moment(date).format("DD-MM-YYYY"),
  },
};
