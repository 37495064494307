import { ICompany } from "../interfaces/company.interface";
import { CrudService } from "./crud.abstract.service";

export const companyService = new (class CompanyService extends CrudService<
  ICompany
> {
  constructor() {
    super("company");
  }
})();
