import { IEvent } from "../interfaces/event.interface";
import { CrudService } from "./crud.abstract.service";

export const eventService = new (class EventService extends CrudService<
  IEvent
> {
  constructor() {
    super("event");
  }
})();
