import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Routes } from "../routes";
import { Layout } from "./layout.component";
import { SecureRoute } from "./secureRoute.component";

export class Router extends Component {
  render() {
    const routes = this.buildRoutes();
    return (
      <BrowserRouter>
        <Layout>
          <Switch>{routes}</Switch>
        </Layout>
      </BrowserRouter>
    );
  }

  /**
   * Build an array with secure routes
   */
  private buildRoutes(): SecureRoute[] {
    const routes = [];
    for (const route of Object.values(Routes)) {
      routes.push(
        <SecureRoute
          key={"route" + route.path}
          access={route.access}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      );
    }

    return routes;
  }
}
