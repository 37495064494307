import moment from "moment";
import { ISchema } from "../interfaces/schema.interface";
import { baseSchema } from "./base.schema";
export const eventSchema: ISchema = {
  ...baseSchema,

  name: {
    type: "string",
    required: true,
    label: "Name",
    placeholder: "Event name",
  },
  location: {
    type: "string",
    required: true,
    label: "Location",
    showTable: () => false,
    placeholder: "The best venue",
  },
  description: {
    type: "text",
    required: true,
    label: "Description",
    placeholder: "",
    showTable: () => false,
  },
  facebook: {
    type: "string",
    required: true,
    label: "Facebook url",
    placeholder: "https://www.facebook.com/awesome-event",
    showTable: () => false,
  },
  registeredParticipants: {
    type: "number",
    required: true,
    showForm: () => false,
    label: "Registered members",
    placeholder: "",
  },
  startDate: {
    type: "datetime",
    required: true,
    label: "Start date",
    placeholder: "Select",
    render: (date: Date) => moment(date).format("DD-MM-YYYY, kk:mm"),
  },
  endDate: {
    type: "datetime",
    required: true,
    label: "End date",
    placeholder: "Select",
    showTable: () => false,
    render: (date: Date) => moment(date).format("DD-MM-YYYY, kk:mm"),
  },
  isMembersOnly: {
    type: "boolean",
    required: true,
    label: "Members only event",
    placeholder: "",
    showTable: () => false,
  },
  allowParticipations: {
    type: "boolean",
    required: true,
    label: "Allow participants to register",
    placeholder: "",
    showTable: () => false,
  },
};
