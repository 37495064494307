export enum Role {
  USER,
  MODERATOR,
  ADMIN,
  PROMOTER,
}

export const RoleValues = {
  [Role.USER]: "User",
  [Role.PROMOTER]: "Promoter",
  [Role.MODERATOR]: "Moderator",
  [Role.ADMIN]: "Administrator",
};
