import React from "react";
import { RouteComponentProps } from "react-router";

export abstract class RouteView<
  P extends RouteComponentProps<any> = RouteComponentProps<any>,
  S = {}
> extends React.Component<P, S> {
  public push(route: string): void {
    this.props.history.push(route);
  }
}
