import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import React, { Component } from "react";
import { IMember } from "../../interfaces/member.interface";
import { IUser } from "../../interfaces/user.interface";
import { userService } from "../../services/user.service";

interface IState {
  members: IMember[];
  users: IUser[];
}

interface IProps {
  onSubmit: (members: IMember[]) => any;
  members: IMember[];
  readonly?: boolean;
}

export class MembersForm extends Component<IProps, IState> {
  public state: IState = {
    members: [],
    users: [],
  };

  componentDidMount() {
    this.setState({
      members: this.props.members || this.state.members,
    });

    this.fetchUsers();
  }

  render() {
    if (this.state.users.length === 0) {
      return <></>;
    }
    return (
      <>
        <Form.Item label={"Members"}>
          <Select
            disabled={this.props.readonly}
            showSearch
            className={[
              "width-full",
              this.props.readonly ? "display-as-text" : "",
            ].join(" ")}
            mode={"multiple"}
            optionFilterProp="children"
            defaultValue={this.state.members.map((member) => member.userId)}
            placeholder={"Select"}
            onChange={(value: string[]) => {
              this.addMembers(value);
              this.submit();
            }}
          >
            {this.state.users.map((user) => (
              <Select.Option key={user._id} value={user._id}>
                {user
                  ? [user.firstName, user.lastName].join(" ")
                  : "Name not found"}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {this.state.members.map((member, index) => (
          <Form.Item key={member.userId} style={{ margin: "25px 0 0 10px" }}>
            <div className="flex width-full">
              {member.user && (
                <div className="flex1">
                  {member.user.firstName} {member.user.lastName}
                </div>
              )}
              <div className="flex1">
                <Input
                  disabled={this.props.readonly}
                  className={[
                    this.props.readonly ? "display-as-text" : "",
                  ].join(" ")}
                  type="text"
                  defaultValue={member.function}
                  placeholder="Function"
                  onChange={(event) => {
                    this.state.members[index].function = event.target.value;
                    this.submit();
                  }}
                />
              </div>
            </div>
          </Form.Item>
        ))}
      </>
    );
  }

  private async fetchUsers() {
    const users = await userService.getAll();
    this.state.members.map((member) => {
      member.user = users.find((user) => user._id === member.userId);
    });

    this.setState({ users });
  }

  private addMembers(userIds: string[]) {
    const members = [];
    for (const id of userIds) {
      const existing = this.state.members.find(
        (member) => member.userId === id
      );
      if (existing) {
        members.push(existing);
        continue;
      }

      const user = this.state.users.find((user) => user._id === id);
      if (user) {
        members.push({
          userId: id,
          user,
          function: "Member",
        });
      }
    }
    this.state.members = members;
    this.forceUpdate();
  }

  private submit() {
    this.props.onSubmit(this.state.members);
  }
}
