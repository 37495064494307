import { IBoard } from "../interfaces/board.interface";
import { CrudService } from "./crud.abstract.service";

export const boardService = new (class BoardService extends CrudService<
  IBoard
> {
  constructor() {
    super("board");
  }
})();
