import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Pagination from "antd/lib/pagination";
import Table from "antd/lib/table";
import { AxiosResponse } from "axios";
import React, { Component, ReactElement } from "react";
import { Subscription } from "rxjs";
import { Sheet } from "strcss";
import { IFormProps } from "../../interfaces/formProps.interface";
import { IHttpOptions } from "../../interfaces/httpOptions.interface";
import { IModel } from "../../interfaces/model.interface";
import { IPermissions } from "../../interfaces/permissions.interface";
import { ISchema } from "../../interfaces/schema.interface";
import { CrudService } from "../../services/crud.abstract.service";
import { antUtils } from "../../utils/ant.utils";
import { CrudModal } from "./crudModal.component";

interface IProps {
  service: CrudService<IModel>;
  schema: ISchema;
  permissions?: IPermissions;

  createTitle?: string;
  updateTitle?: string;
  customCreateForm?: React.ComponentClass<IFormProps>;
  customUpdateForm?: React.ComponentClass<IFormProps>;
  title?: ReactElement<any>[];
  onCreate?: (model: IModel) => Promise<IModel>;
  onUpdate?: (model: IModel) => Promise<IModel>;
  onRowClick?: (model: IModel) => any;
  onNewClick?: () => any;
  fetchData?: (options: IHttpOptions) => Promise<IModel[]>;
}

interface IState {
  page: number;
  itemsPerPage: number;
  total: number;
  models: IModel[];
  columns: any[];
  modals: {
    create: boolean;
    update: boolean;
  };
  activeModel: IModel;
  loading: boolean;
  searchQuery: string;
}

export class CrudTable extends Component<IProps, IState> {
  private sortOrder = ["-createdAt"];
  private responseSubscription: Subscription;
  public state = {
    page: 1,
    itemsPerPage: 10,
    total: 0,
    models: [],
    columns: [],
    modals: {
      create: false,
      update: false,
    },
    activeModel: null,
    loading: false,
    searchQuery: "",
  };

  componentDidMount() {
    this.setState({ columns: antUtils.schemaToColumns(this.props.schema) });
    this.fetchPage();
    this.responseSubscription = this.props.service.responseObservable.subscribe(
      this.retrieveTotalCount.bind(this)
    );
  }

  componentWillUnmount() {
    this.responseSubscription.unsubscribe();
  }

  render() {
    return (
      <>
        <Table
          onChange={this.handleChange.bind(this)}
          onRow={(model: IModel) => ({
            onClick: () => {
              this.setState({ activeModel: model });
              if (this.props.onRowClick) {
                this.props.onRowClick(model);
              }
              this.toggleUpdateModal(true);
            },
          })}
          title={() => (
            <div className="flex-space-between flex-align-middle">
              <Button
                hidden={!(this.props.permissions || { create: true }).create}
                onClick={() => {
                  if (this.props.onRowClick) {
                    this.props.onNewClick();
                  }
                  this.toggleCreateModal(true);
                }}
                type="primary"
              >
                Add new
              </Button>
              <Input
                style={{ margin: "0 15px" }}
                onChange={(el) => this.setSearchQuery(el.currentTarget.value)}
                placeholder="Search table"
              />

              <Button
                hidden={!(this.props.permissions || { create: true }).create}
                onClick={() => this.props.service.downloadCsv()}
                type="default"
                size="small"
              >
                Download .csv
              </Button>

              {this.props.title ? this.props.title : ""}
            </div>
          )}
          loading={this.state.loading}
          className={sheet.map.table}
          columns={this.state.columns}
          dataSource={this.state.models}
          pagination={false}
          bordered
          rowClassName={sheet.map.row}
          size="middle"
          footer={() => (
            <Pagination
              showSizeChanger
              onShowSizeChange={(current, size) => {
                this.state.itemsPerPage = size;
                this.state.page = current;
                this.fetchPage();
              }}
              current={this.state.page}
              total={this.state.total}
              pageSize={this.state.itemsPerPage}
              onChange={(page) => {
                this.state.page = page;
                this.fetchPage();
              }}
            />
          )}
        />

        <CrudModal
          title={this.props.createTitle || "Add new"}
          visible={this.state.modals.create}
          onClose={() => {
            this.toggleCreateModal(false);
            this.fetchPage();
          }}
          onCreate={this.props.onCreate}
          onUpdate={this.props.onUpdate}
          service={this.props.service}
          schema={this.props.schema}
          permissions={this.props.permissions}
          action="create"
          customForm={this.props.customCreateForm}
        />

        {this.state.activeModel && (
          <CrudModal
            title={
              this.props.updateTitle ||
              (this.props.permissions && this.props.permissions.update === false
                ? "Details"
                : "Edit")
            }
            visible={this.state.modals.update}
            onCreate={this.props.onCreate}
            onUpdate={this.props.onUpdate}
            onClose={() => {
              this.toggleUpdateModal(false);
              this.setState({ activeModel: null });
              this.fetchPage();
            }}
            model={this.state.activeModel}
            service={this.props.service}
            schema={this.props.schema}
            permissions={this.props.permissions}
            action="update"
            customForm={this.props.customUpdateForm}
          />
        )}
      </>
    );
  }

  private handleChange(
    pagination,
    filter,
    sort: { columnKey: string; order: "ascend" | "descend" }
  ) {
    if (Object.keys(sort).length) {
      this.sortOrder = [
        [sort.order === "ascend" ? "" : "-", sort.columnKey].join(""),
      ];
      this.fetchPage();
    }
  }

  private searchTimeout: number;
  private setSearchQuery(searchQuery: string) {
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }
    this.searchTimeout = window.setTimeout(
      () => this.setState({ searchQuery }, () => this.fetchPage()),
      500
    );
  }

  /**
   * Fetch all models for target page
   * @param number
   */
  private async fetchPage(): Promise<void> {
    this.setState({ loading: true });
    const options = {
      sort: this.sortOrder,
      offset: (this.state.page - 1) * this.state.itemsPerPage,
      limit: this.state.itemsPerPage,
      search: this.state.searchQuery,
    };

    let models = [];
    if (this.props.fetchData) {
      models = await this.props.fetchData(options);
    } else {
      models = await this.props.service.getAll(options);
    }

    // set react keys
    for (const model of models) {
      model["key"] = model._id;
    }

    this.setState({ models, loading: false });
  }

  /**
   * Retrieve the total count header if available
   * @param response
   */
  private retrieveTotalCount(response: AxiosResponse): void {
    if (response === null) {
      return;
    }

    const total = +(response.headers || {})["x-total-count"];
    if (!isNaN(total)) {
      this.setState({ total });
    }
  }

  /**
   * Sets the create modal (in)visible
   * @param show
   */
  private toggleCreateModal(show: boolean): void {
    this.setState({
      modals: {
        update: false,
        create: show,
      },
    });
  }

  /**
   * Sets the update modal (in)visible
   * @param show
   */
  private toggleUpdateModal(show: boolean): void {
    this.setState({
      modals: {
        update: show,
        create: false,
      },
    });
  }
}

const sheet = new Sheet(`
  map table
    marginBottom 20px

  map row
    cursor pointer
`);
