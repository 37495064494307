import { Component } from "react";

export const stateUtils = new class StateUtils {
  /**
   * Save the changed value to the state
   * @param event
   */
  public writeChange(context: Component, path, value): void {
    let keys = path.split(".");

    if (keys.length === 0) {
      return;
    }

    let state = context.state;
    for (let i = 0; i < keys.length - 1; i++) {
      state = state[keys[i]];
    }
    state[keys[keys.length - 1]] = value;

    context.forceUpdate();
  }
}();
