import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Sheet } from "strcss";
import logo from "../assets/images/pattern-logo.png";
import { Routes } from "../routes";
import { authenticationService } from "../services/authentication.service";
import { authenticationUtils } from "../utils/authentication.util";
import { Navigation } from "./navigation.component";

export class Header extends Component {
  render() {
    return (
      <header className={sheet.map.header}>
        <div className={sheet.map.row}>
          <div className={["container", sheet.map.separate].join(" ")}>
            <div className={sheet.map.logo}>
              <img height={20} src={logo} />
            </div>
            {authenticationService.getSessionToken() && (
              <div className={sheet.map.session}>
                Welcome {authenticationUtils.getUserFirstName()},{" "}
                <NavLink to={Routes.logout.path}>logout</NavLink>
              </div>
            )}
          </div>
        </div>
        <div className={sheet.map.row}>
          <div className="container">
            <Navigation />
          </div>
        </div>
      </header>
    );
  }
}

const sheet = new Sheet(`
  map header
    marginBottom 30px

  map separate
    display flex
    justifyContent space-between

  map logo
    fontWeight bold

  map row
    padding 20px 0
    backgroundColor #FFF
    borderBottom 1px solid #ebebeb
`);
