import Card from "antd/lib/card";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CrudTable } from "../components/crud/crudTable.component";
import { Role } from "../enums/role.enum";
import { IPermissions } from "../interfaces/permissions.interface";
import { navigationSchema } from "../schemas/navigation.schema";
import { navigationService } from "../services/navigation.service";
import { appUtils } from "../utils/app.util";
import { authenticationUtils } from "../utils/authentication.util";
import { RouteView } from "./routeView.abstract";

interface IState {
  permissions: IPermissions;
}

export class NavigationView extends RouteView<
  RouteComponentProps<any>,
  IState
> {
  public state = {
    permissions: {
      create: authenticationUtils.satisfiesRoles([Role.ADMIN]),
      update: authenticationUtils.satisfiesRoles([Role.ADMIN]),
      delete: authenticationUtils.satisfiesRoles([Role.ADMIN]),
    },
  };

  componentDidMount() {
    appUtils.setTitle("Navigation");
  }

  render() {
    return (
      <Card title={"Navigation"}>
        <CrudTable
          permissions={this.state.permissions}
          service={navigationService}
          schema={navigationSchema}
        />
      </Card>
    );
  }
}
