import React from "react";
import { Routes } from "../routes";
import { authenticationService } from "../services/authentication.service";
import { appUtils } from "../utils/app.util";
import { RouteView } from "./routeView.abstract";

export class LogoutView extends RouteView {
  public componentDidMount() {
    appUtils.setTitle("Logout");

    this.logout();
  }

  public render() {
    return <></>;
  }

  private async logout(): Promise<void> {
    await authenticationService.logout();
    this.push(Routes.login.path);
  }
}
