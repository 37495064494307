import { Role } from "../enums/role.enum";
import { ICredentials } from "../interfaces/credentials.interface";
import { ISessionToken } from "../interfaces/sessionToken.interface";
import { HttpService } from "./http.service";

export const authenticationService = new (class AuthenticationService extends HttpService {
  private readonly STORAGE_KEY = "session";
  private readonly controller = "/authentication";

  /**
   * Authenticate with the server
   * @param credentials
   */
  public async authenticate(credentials: ICredentials): Promise<ISessionToken> {
    const sessionToken = await this.postRequest<ISessionToken>(
      "/authentication",
      credentials
    );
    if (sessionToken) {
      if (sessionToken.user.role !== Role.USER) {
        this.setSessionToken(sessionToken);
      } else {
        throw Error();
      }
    }

    return sessionToken;
  }

  /**
   * Validate the current session
   */
  public async validate(): Promise<ISessionToken> {
    let response = null;
    response = await this.postRequest<ISessionToken>(
      [this.controller, "validate"].join("/"),
      null
    );

    return response;
  }

  /**
   * Disable the current session
   */
  public async logout(): Promise<any> {
    const response = await this.postRequest<ISessionToken>(
      "/authentication/logout",
      null
    );
    this.clearSessionToken();

    return response;
  }

  /**
   * Get the stored session token
   */
  public getSessionToken(): ISessionToken {
    const sessionToken = localStorage.getItem(this.STORAGE_KEY);
    return sessionToken ? JSON.parse(sessionToken) : null;
  }

  /**
   * Store a session token
   * @param sessionToken
   */
  public setSessionToken(sessionToken: ISessionToken): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(sessionToken));
  }

  /**
   * Clear the stored session token
   */
  public clearSessionToken(): void {
    localStorage.removeItem(this.STORAGE_KEY);
  }
})();
