import { ISchema } from "../interfaces/schema.interface";

export const antUtils = new (class AntUtils {
  /**
   * Build table column according to the schema
   */
  public schemaToColumns(schema: ISchema): any[] {
    const columns = [];
    for (const key of Object.keys(schema)) {
      const properties = schema[key];
      if (properties.showTable && properties.showTable() === false) {
        continue;
      }

      const column: any = {
        title: properties.label || key,
        dataIndex: key,
        key,
        sorter: (a, b) => 0,
        sortOrder: false,
      };

      // define the render method
      column.render = (text, model) => {
        if (model[key] === null) {
          return "";
        }

        if (properties.isArray && Array.isArray(model[key])) {
          return model[key]
            .map((val) => {
              if (properties.render) {
                return properties.render(val);
              }
              if (typeof properties.type === "object") {
                return properties.type[val];
              }
              return text;
            })
            .join(", ");
        }
        if (properties.render) {
          return properties.render(model[key]);
        }
        if (typeof properties.type === "object") {
          return properties.type[model[key]];
        }
        return text;
      };

      columns.push(column);
    }

    return columns;
  }
})();
